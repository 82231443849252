@import "site/sass-mixins.scss";


.page-standard .service-outer-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    h2 {
        margin-bottom: 0.3em;
    }
    .service-wrapper {
        display: flex;
        flex-direction: row;
        .sitepix-wrapper {
            flex: 0 0 100px;
            @media screen and (min-width: 550px) {
                flex: 0 0 200px;
            }
            padding-right: 20px;
            img {
                display: block;
                width: 100%;
            }
        }
        div {
            flex: 1 1 auto;
        }
    
    }
}






