@import "site/sass-mixins.scss";

// Logo
.bluesword-logo {
  h2 {
    margin-top:0;
    margin-bottom: 0;
    line-height: 0.9em;
    height: 0.75em;
    font-size: 3em;
    font-family: $font-logo;
    font-weight: 500;
    span {
      padding-left: 0.1em;
      font-weight: 300;
    }
  }

}

.bluesword-logo-small {
  display: flex;
  flex-direction: column;
  h3 {
    color: $site-darkblue;
    font-family: "proxima-nova-condensed", sans-serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1em;
    padding-bottom: 3px;
    margin: 0;
    // font-size: 1.5em;
    font-size: 1.2rem;
  }
  .logo-text {
    display: flex;
    flex-direction: row;
    align-items: end;
    h2 {
      font-family: "corporate-a-condensed", serif;
      font-weight: 700;
      font-style: normal;
      color: $site-darkblue;
      font-size: 2.5rem;
      margin-top: 0;
      margin-bottom: 0;
    }
    .logo-left {
  
    }
    .logo-mid {
      // align-self: end;
      // width: 13px;
      // padding-left: 5px;
      // padding-right: 5px;
      // img {
      //   width: 100%;
      // }
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      svg {
        padding-left:2px;
        padding-right: 2px;
        height: 40px;
        fill: white;
        path:first-child {
          fill: $site-blue;
        }
      }
    }
    .logo-right {
  
    }
  }


}

#topmenuwrapper {
  .bluesword-logo-small {
    // display: flex;
    // flex-direction: column;
    .logo-text {
      // display: flex;
      // flex-direction: row;
      // h2 {
      //   font-family: "corporate-a-condensed", serif;
      //   font-weight: 700;
      //   font-style: normal;
      //   color: $site-darkblue;
      //   font-size: 2.5rem;
      //   margin-top: 0;
      //   margin-bottom: 0;
      // }
      .logo-left {
    
      }
      .logo-mid {
        // align-self: end;
        // width: 13px;
        // padding-left: 5px;
        // padding-right: 5px;
        // img {
        //   width: 100%;
        // }
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        svg {
          height: 35px;
          // fill: white;
          // path:first-child {
          //   fill: red;
          // }
        }
      }
      .logo-right {
    
      }
    }
    h3 {
      // color: $site-darkblue;
      // font-family: "proxima-nova-condensed", sans-serif;
      // font-weight: 300;
      // font-style: normal;
      // line-height: 1em;
      // padding-bottom: 3px;
      // margin: 0;
      // font-size: 1.5em;
      font-size: 1rem;
    }
  
  }
}

#footerwrapper {
  .footer-content {

    .footer-logo-link{
      .full-logo {
        ul {
          margin-top: 0.5em;
          margin-top: 0.5em;
          li {
            color: white;
            font-family: "proxima-nova-condensed", sans-serif;
            font-weight: 300;
            font-style: normal;
            line-height: 1em;
            padding-bottom: 3px;
            margin: 0;
            // font-size: 1.5em;
            font-size: 1rem;
          }
        }
  
      }
      max-width: 300px;
      .bluesword-logo-small {
        // display: flex;
        // flex-direction: column;

        .logo-text {
          // display: flex;
          // flex-direction: row;
          h2 {
            // font-family: "corporate-a-condensed", serif;
            // font-weight: 700;
            // font-style: normal;
            color: white;
            // font-size: 2.5rem;
            // margin-top: 0;
            // margin-bottom: 0;
          }
    
          .logo-left {
            // color: white;
          }
          .logo-mid {
            // align-self: end;
            // width: 13px;
            // padding-left: 5px;
            // padding-right: 5px;
            // img {
            //   width: 100%;
            // }
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
            // align-items: center;
            svg {
              height: 35px;
              fill: $site-blue;
              path:first-child {
                fill: white;
              }
            }
          }
          .logo-right {
        
          }
        }
        h3 {
          color: white;
          // font-family: "proxima-nova-condensed", sans-serif;
          // font-weight: 300;
          // font-style: normal;
          // line-height: 1em;
          // padding-bottom: 3px;
          // margin: 0;
          // font-size: 1.5em;
          font-size: 1rem;
        }
      
      }
    }



  }

}





.home-banner {
  overflow-y: clip;
  .hero-image-title {
    width: 100vw;
    top: 0;
    left: 0;
    right: initial;
    display: block;
    .hero-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      outline: 1px solid green;
      background-color: rgba(255,255,255,0.7);
      
      height: 50vw;
      font-size: 0.5rem;
      @media screen and (min-width: 550px) {
        height: 33.3vw;
        font-size: 1rem;
      }
      @media screen and (min-width: 1200px) {
        height: 25vw;
      }
      .logo-container {
        width:fit-content;
        align-self: center;

        .bluesword-logo2 {
          // margin: auto;
          display: flex;
          flex-direction: row;
          // outline: 1px solid red;
          // font-size: 1rem;
          width: fit-content;
          h2 {
            font-family: "corporate-a-condensed", serif;
            font-weight: 700;
            font-style: normal;
            color: $site-darkblue;
            // font-size: 4em;
            font-size: 8vw;
            margin-top: 0;
            margin-bottom: 0;
          }
          .logo-left {
            color: green;
          }
          .logo-mid {
            // background-color: yellow;
            width: 5vw;
            padding-left: 1vw;
            padding-right: 1vw;

            img {
              width: 100%;
              svg path {
                fill: green;
              }
            }
          }
          .logo-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .logo-tagline {
              display: flex;
              flex-direction: column;
              // outline: 1px solid blue;
              h3 {
                color: $site-darkblue;
                font-family: "proxima-nova-condensed", sans-serif;
                font-weight: 400;
                font-style: normal;
                margin: 0;
                // font-size: 1.5em;
                font-size: 3.2vw;
                &.logo-services {
                  // font-size: 1.2em;
                  font-size: 2.3vw;
                }
              }
            }
        
          }
        
        
        }

      }



      

    }

  }
}



