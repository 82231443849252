@import "site/sass-mixins.scss";

.page-standard#page-home {
  padding-left: 0;
  padding-right: 0;
  max-width: initial;
  .margin-container {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

#page-home {
  padding-bottom: 20px;
}

.page-standard li {
  font-weight: 300;
}

.page-standard .services-home {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $site-lightblue;
  color:white;
  h2 {
    color:white;
  }
  ul {
    margin-top: 0.5em;
    margin-bottom: 0;
  }

}

.page-standard .sectors-home {
  padding-top: 10px;
  // padding-bottom: 10px;
  // background-color: $site-lightblue;
  // color:white;
  // h2 {
  //   color:white;
  // }
  ul {
    columns: 2;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

}


#page-home .accreditations-home-banner {
    width: 100%;
    // background-color: $site-blue;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    h2 {
      color: #333;
      align-self: flex-start;
      @include page-section;
    }
    #accreditations-wrapper {
      width: 90%;
      padding-top: 0;
      // picture img {
      //   height: 5vw;
      // }
    }

}



a.showcase-home-banner {
  text-decoration: none;
}

#page-home a.recent-projects.margin-container {
  &:visited, &:link {
    text-decoration: none;
    color: #333;
  }
    width: 100%;
    // background-color: $site-blue;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h2 {
      &:visited, &:link {
        text-decoration: none;
      }
      text-decoration: none;
      color: $site-darkblue;
      align-self: flex-start;
      @include page-section;
    }
    .recent-projects__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 600px) {
        flex-direction: row;
      }
      justify-content: space-between;
      flex-wrap: nowrap;
      .sitepix-wrapper {
        flex: 0 0 30%;
        padding-right: 20px;
        picture img {
          width: 100%;
        }
      }

    }
    .recent-projects__content {
      align-self: flex-start;
      flex: 1 1 70%;
      p {
        // &:first-child {
        //   margin-top :0;
        // }
        flex-grow: 1;
        font-family: $font-copy;
        // font-size: 1.3rem;
        font-weight: 300;
        color: #333;
        text-align: left;
      }
    }

}


#page-home a.about-home-banner {
  text-decoration: none;
  display: block;
}

#page-home .about-home-banner {
  // height: 200px;
    width: 100%;
    // background-color: $site-blue;
      // background-color: beige;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(135,153,212);
background: linear-gradient(20deg, rgba(135,153,212,1) 4%, rgba(229,235,254,1) 25%, rgba(255,255,255,1) 49%, rgba(245,239,242,1) 76%, rgba(235,166,205,1) 100%);
    position: relative;
    &__pattern {
      // z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img.tophex {
       position: absolute;
       top: 0;
       left: 0;
      }
      img.bottomhex {
        position: absolute;
        transform: rotate(180deg);
        bottom: 0;
        left: 0;
       }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      z-index: 10;

      h2 {
        color: #333;
        align-self: flex-start;
        @include page-section;
      }


    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      justify-content: space-between;
      flex-wrap: nowrap;
      align-self: center;
      width: 100%;
      .sitepix-wrapper {
        // flex: 0 0 80vw;
        width: 80%;
        max-width: 300px;
        align-self: center;
        @media screen and (min-width: 768px) {
          max-width: initial;
          flex: 0 0 25vw;
          padding-right: 20px;
        }
        @media screen and (min-width: 1024px) {
          flex: 0 0 300px;
        }

        picture img {
          width: 100%;
        }
      }
      >div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      p {
        max-width: 50ch;
        // flex-grow: 1;
        font-family: $font-copy;
        font-size: 1.3rem;
        font-weight: 500;
        color: #333;
        margin: 0.5em auto;
        text-align: center;
        // &:first-child {
        //   margin-top :0;
        // }
        // font-family: $font-copy;
        // font-size: 1rem;
        // font-weight: 500;
        // color: #333
      }
    }

}




#page-home .main-home-banner {
  // height: 500px;
  width: 100%;
  background-size: contain;
  // background-color: beige;
  // background-image: url(https://appzuka-web.s3.eu-west-2.amazonaws.com/bluesword/imagestream/n/background/hex1-4-1024.jpg);
  // background-color: blueviolet;
  // background: rgb(34,58,137);

  background: rgb(34,58,137);
  // background: linear-gradient(20deg, rgba(34,58,137,1) 3%, rgba(20,37,91,1) 29%, rgba(30,8,32,1) 52%, rgba(121,4,71,1) 77%, rgba(187,8,110,1) 98%);
  background: linear-gradient(20deg, rgba(187,8,110,1) 3%, rgba(121,4,71,1) 29%, rgba(30,8,32,1) 52%, rgba(20,37,91,1) 77%, rgba(34,58,137,1) 98%);
  


  // background: linear-gradient(29deg, rgba(34,58,137,1) 3%, rgba(20,37,91,1) 45%, rgba(121,4,71,1) 56%, rgba(221,8,129,1) 98%);
  // background-image: url('./whitehex.svg'), url('./bl.svg');
  // background-position: right top, left bottom;
  // background-repeat: no-repeat, no-repeat;
  color: white;
  text-align: center;
  font-family: $font-heading;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &__pattern {
    // z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img.tophex {
     position: absolute;
     top: 0;
     left: 0;
    }
    img.bottomhex {
      position: absolute;
      transform: rotate(180deg);
      bottom: 0;
      left: 0;
     }
  }
  &__headline {
    z-index: 10;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 4.5vw;
    font-weight: 500;
  }
  &__mission {
    z-index: 10;
    margin: 0;
    padding-bottom: 20px;
    font-size: 2.5vw;
    font-weight: 300;
    padding-left: 10%;
    padding-right: 10%;

  }
  &__box-wrapper {
    z-index: 10;
    display: flex;

    flex-direction: column;
    @media screen and (min-width: 750px) {
      flex-direction: row;
    }
    justify-content: space-around;
    padding-bottom: 20px;
  }
  &__box {
    flex: 0 1 80%;
    margin-bottom: 1em;
    @media screen and (min-width: 750px) {
      flex: 0 1 27%;
    }
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @media screen and (min-width: 750px) {
      flex-direction: column;
    }
  }
  &__box-heading {
    flex: 0 0 33%;
    @media screen and (min-width: 750px) {
      flex: 0 0 auto;
    }
    svg {
      width: 80px;
      height: 80px;
    }
    h2 {
      color: $site-blue;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__box-list {
    color: #333;
    padding-left: 20px;
    margin: 0;
    li {
      font-family: $font-copy;
      list-style-type: disc;
      text-align: left;
      font-size: 14px;
      line-height: 1em;
      padding-bottom: 10px;
    }
  }
}

.hero-image-title {
  position: absolute;
  right: 50px;
  top: 20px;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 600px) {
    width: 100%;
    left: 0;
    right: initial;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
}

// #bannerwrapper.photobanner.home-banner {
//   height: 0;
//   padding-bottom: 66.6%;
//   min-height: initial;
//   @media screen and (min-width: 768px) {
//     padding-bottom: 50%;
//   }
//   @media screen and (min-width: 1200px) {
//     padding-bottom: 33.3%;
//   }
// }

