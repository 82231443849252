@import "site/sass-mixins.scss";

// Overrides


@mixin dark-header-colors() {
        // background-color: #3c789c; //68b0e0
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3c789c+0,68b0e0+100 */
    background: $site-blue; /* Old browsers */
    background: -moz-linear-gradient(left, $site-blue 0%, #68b0e0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $site-blue 0%,#68b0e0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, #2945a3 0%, $site-blue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$site-blue', endColorstr='#68b0e0',GradientType=1 ); /* IE6-9 */
}

@mixin light-header-colors() {
    background: white;
}

body {
  overflow-x: hidden;
}

// .full-logo {
//   padding-top: 5px;
//   padding-bottom: 3px;
//   p {
//     display: none;
//     color:black;
//     margin: 0;
//     line-height: 1.2em;
//   }
// }

#headmenuwrapper {
  .topmenuouter {
    @include light-header-colors();
    .topmenubar {
      max-width: 1200px;
      margin: auto;
      padding-left: 20px;
      padding-right: 20px;
      .menubarlogolink {
        margin-left: 0;
        flex-grow: 0;
        .full-logo {
          margin-top: 9px;
          margin-bottom: 3px;
          p {
            margin: 0;
            display: inline-block;
            text-align: left;
            width: 100%;
            font-family: owners, sans-serif;
            font-weight: 300;
            font-size: 18px;
            letter-spacing: 0.7px;
          }
        }
      }
      .content { 
        flex-grow: 1;
      }
    }
  }
  .hamburger-icon {
    margin-right: 0;
  }
}

#footerwrapper {
    @include dark-header-colors();
    .footer-content .footer-logo-link {
      padding-right: 20px;
      max-width: 300px;
      justify-content: flex-start;
    }
}

#topmenuwrapper .topmenuouter {
  background-color:rgba(255,255,255,0.9);
  .topmenubar {
    max-width: 1200px;
    margin: auto;
    .menubarlogolink {
      .full-logo {
        // margin-top: 5px;
        h2 {
          font-size: 2.2em;
        }
        p {
          display: none;
        }

      }
    }

    #menucontent ul li a:visited {
      color: black;
    }
    #menucontent ul li a:hover {
      color: $highlight;
    }
  }
}

#footerwrapper {
  .footer-content {
    background-color: $site-darkblue;
    .footer-address, .footer-contacts {
      font-size: 0.9em;
    }
    .footer-logo-link {
      .bluesword-logo {
        font-size: 0.7rem;
      }
    }
    .footer-address > .footer-contact-line {
      .location-icon {
        visibility: hidden;
        height: 1.5em;
      }
    }
    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      .footer-logo-link {
        width: 100%;
        max-width: initial;
        padding-bottom: 10px;
      }
    }
  }
  .footer-copyright-wrapper {
    background-color: $site-deepblue;
}

}





#sub-head-menu {
  padding-left: 0;
  padding-right: 0;
  max-width: initial;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  #sub-head-menu-ul {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    >li {
      padding-left: 0;
    }
    @media screen and (max-width: 599px) {
      a:nth-child(2) li {
        padding-left: 0;
      }
    }
    a:last-child li {
      padding-right: 0;
    }
    li {
      list-style-type: none;
    }
  }
}

#bannerwrapper {
  margin-bottom: 15px;
}

.page-standard {
  h2 {
    @include page-section;
    color: $site-darkblue;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
  }
  p {
    font-weight: 300;
    font-size: 1.1em;
    margin-top: 0;
  }
}
