
@import "site/sass-mixins.scss";

#bannerwrapper.photobanner {
    height: 0;
    padding-bottom: 50%;
    min-height: initial;
    @media screen and (min-width: 550px) {
        min-height: initial;
        padding-bottom: 33.3%;
    }
    @media screen and (min-width: 1200px) {
        min-height: initial;
        padding-bottom: 25%;
    }
}
.footer-content {
  .footer-logo-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
}
.full-logo {
  color: $site-darkblue;
}



.page-standard {
  h2 {
    @include page-section;
  }
}