.page-standard#page-contact {
  padding-left: 0;
  padding-right: 0;
  max-width: initial;
  .margin-container {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin: auto;
  }
}

#page-contact {
    .contact-wrapper {
        padding-bottom: 20px;
        .contact-address-row {
            padding-bottom: 20px;
            &:last-child {
                padding-bottom: 0;
            }
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            @media (min-width: 768px) {
                flex-direction: row;
            }
            .contact-details {
                // background-color: yellow;
                // min-height: 200px;
                flex: auto;
                width: 100%;
                @media (min-width: 768px) {
                    width: 50%;
                    padding-right: 20px;
                    box-sizing: border-box;
                }
                flex-shrink: 0;

                .contact-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-bottom: 10px;
                    &:last-child {
                        padding-bottom: 0;    
                    }
                    p {
                        margin: 0;
                    }
                    .contact-icon {
                        width: 25px;
                        @media (min-width: 499px) {
                            width: 30px;
                        }
                        margin-right: 10px;
                        fill: black;
                        flex-grow: 0;
                        flex-shrink: 0;
                    }
                    .contact-label {
                        flex-grow: 0;
                        flex-shrink: 0;
                        width: 100px;
                    }
                    .contact-row-content {
                        max-width: 300px;
                    }
                }
            }
            .contact-map {
                min-height: 400px;
                width: 100%;
                margin-top: 20px;
                @media (min-width: 767px) {
                    margin-top: 0;
                    width: 50%;
                }
                flex-shrink: 0;
                background-color: #ccc
            }
            .contact-text {
                width: 100%;
                padding-left: 20px;
                box-sizing: border-box;
                @media (min-width: 1024px) {
                    margin-top: 0;
                    width: 50%;
                }
                flex-shrink: 0;
            }
        }
    }
}

.form-wrapper > h2 {
  display: none;
}

