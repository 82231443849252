@mixin ipad2 { // and ipad1
    @media screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 1) {
        @content;
    }
}

// https://color.adobe.com/Allshelter%20-%20with%20light%20blue-color-theme-10776708
$site-lightblue: #2BA4DD;
$site-darkblue: #004B87;
$site-green: #6CC24A;
$site-orange: #ED8B00;
$site-yellow: #FBB616;
$site-deepblue: #003f72;

$appzuka: #f28b32;
$highlight: #ED8B00;
// $highlight-highlight: #facba2;;
$hover: #ED8B00;


$site-blue: #004B87;
$site-pink: #dd0881;


@mixin page-section {
  font-family: $font-heading;
  font-weight: 500;
  font-size: 2rem;
}


// $font-heading: owners-narrow, sans-serif;
$font-heading: "corporate-a-condensed", serif;


@mixin headingWeight($weight) {
    font-weight: 700;
    // @if $weight == 500 {
    //     font-weight: 500;
    // } @else {
    //     @error "Missing weight for heading font: "+$weight
    // }
}

@mixin fontHeading($weight) {
    font-family: $font-heading;
    @include headingWeight($weight);
    font-size: 1.3rem;
    @media screen and (min-width: 500px) {
        font-size: 1.5rem; 
    }
}

// Open Sans used: 300, 400, 600
// Open Sans is loaded from cdn in home.scss, preloaded in html
$font-menu: 'Open Sans', sans-serif;
@mixin menuWeight($weight) {
    @if $weight == 300 {
        font-weight: 300;
    } @else if $weight == 400 {
        font-weight: 400;
    } @else if $weight == 600 {
        font-weight: 600;
    } @else {
        @error "Missing weight for menu font: "+$weight
    }
}

@mixin fontMenu($weight) {
    font-family: $font-menu;
    @include menuWeight($weight);
    // font-size: 1.2rem;
    // @media screen and (min-width: 500px) {
    //     font-size: 1.5rem; 
    // }
}

$font-copy: 'Open Sans', sans-serif;
@mixin copyWeight($weight) {
    @if $weight == 300 {
        font-weight: 300;
    } @else if $weight == 400 {
        font-weight: 400;
    } @else if $weight == 600 {
        font-weight: 600;
    } @else {
        @error "Missing weight for copy font: "+$weight
    }
}

@mixin fontCopy($weight:300) {
    font-family: $font-copy;
    @include copyWeight($weight);
    font-size: 0.9rem;
    @media screen and (min-width: 500px) {
        font-size:1rem; 
    }
}

$font-footer: 'Open Sans', sans-serif;
@mixin footerWeight($weight) {
    @if $weight == 300 {
        font-weight: 300;
    } @else if $weight == 400 {
        font-weight: 400;
    } @else if $weight == 600 {
        font-weight: 600;
    } @else {
        @error "Missing weight for footer font: "+$weight
    }
}


@mixin fontFooter($weight:300) {
    font-family: $font-footer;
    @include footerWeight($weight);
    font-size: 1.2rem;
    @media screen and (min-width: 500px) {
        font-size: 1rem; 
    }
}


// Dark Header
$menucontentcolor: null;
$subheadmenucontentcolor: null;
$hovermenucontentcolor: null;
$sidemenucontentcolor: null;
$drophovermenucontentcolor: null;
$scrollmenucontentcolor: null;
$hamburgercolor: null;
$scrollhamburgercolor: null;
$dropmenucolor: null;
$headsidemenucolor: null;
$headsidemenucontentcolor: null;

$footertextcolor: white ;
$tophovermenucolor: rgba(82,148,191,0.9);

@mixin theme-colors($dark-header: true) {
    @if $dark-header {
        $menucontentcolor: white !global;
        $subheadmenucontentcolor: white !global;
        $hovermenucontentcolor: white !global;
        $drophovermenucontentcolor: white !global;
        $sidemenucontentcolor: white !global;
        $scrollmenucontentcolor: white !global;
        $hamburgercolor: white !global;
        $scrollhamburgercolor: white !global;
        $dropmenucolor: #68b0e0 !global;
        
        $footertextcolor: white !global;
        $tophovermenucolor: rgba(82,148,191,0.9) !global;
    } @else {
        $menucontentcolor: #555 !global;
        $subheadmenucontentcolor: #555 !global;
        $hovermenucontentcolor: #555 !global;
        $drophovermenucontentcolor: black !global;
        $sidemenucontentcolor: black !global;
        $scrollmenucontentcolor: black !global;
        $hamburgercolor: #555 !global;
        $scrollhamburgercolor: black !global;
        $dropmenucolor: rgba(255,255,255,0.95) !global;
        
        $footertextcolor: white !global;
        $tophovermenucolor: rgba(255,255,255,0.95) !global;

        $headsidemenucolor: rgba(255, 255, 255, 0.95) !global;
        $headsidemenucontentcolor: #555 !global;
  }
}

@include theme-colors($dark-header: false);

$font-logo: owners-narrow, sans-serif;

// $tophovermenucolor: rgba(104,176,224,0.9) ;