.gallery-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    gap: 0px 0px; // Gaps don't really work due to the hidden row.  Use top and bottom padding.
    .fullrow {
      flex: 0 0 100%;
      height: 0;
      padding-top: 80;
      padding-bottom: 0;
      transition: padding-bottom 1s ease-out;
      overflow: hidden;
      // max-width: 34%;
      // outline: 2px solid red;
    }
    .sitepix-not-selected {
      flex: 0 1 calc(33.3% - 5px); // Gaps will be 7.5px
      box-sizing: border-box;
      // max-width: calc(33.3% - 3.3px);
      // max-width:33.3%;
      transition: opacity 1s ease-out;
      padding-top: 8px;
      &.shown {
        opacity: 0.5;
      }
    }
    .sitepix-selected {
      flex: 0 0 100%;
      // max-width: 34%;
      // outline: 2px solid red;

    }
    .sitepix-wrapper {

      // &:nth-child(5) {
      //   max-width: 100%;
      //   width: 100%;
      //   flex: 0 0 100%;
      //   outline: 2px solid red;
      // }
      picture {
        // flex: auto;
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // padding-bottom: 20px;
        // // &:first-child {
        // //     outline: 1px solid red;
        // //     flex: 0.1 1 10%;
        // // }
        // padding-right: 10px;
        // &:last-child {
        //     padding-right: 0;
        // }
        img {
            display: block;
            width: 100%;
            height: auto;
            // height: 150px;
            // @media screen and (min-width: 550px) {
            //     height: 150px;
            // }
            // @media screen and (min-width: 768px) {
            //     height: 200px
            // }
            // width: auto;
            // // max-height: 30px;
        }

    }
    }



}