@import "site/sass-mixins.scss";

.statement-banner {
    width: 100%;
    background-color: $site-darkblue;
    background-position-y: center;
    // background-image: url(https://appzuka-web.s3.eu-west-2.amazonaws.com/bluesword/imagestream/n/background/graph-5-1024.jpg);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      h2 {
        width: 95vw;
        color: white;
        text-align: center;
        font-family: $font-heading;
        font-weight: 500;
        font-size: 4vw;
      }
    }
    &.banner-lightblue {
        background-color: $site-lightblue;
    }

}