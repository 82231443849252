@import "site/sass-mixins.scss";


#page-accreditations {
  h2 {
    @include page-section;
  }
}


.about-bluesword {
  h2 {
    @include page-section;
  }
}

